import { useState } from 'react'
import { Layout as LayoutAnt, Steps, Button, message } from 'antd'
import { getVenueId, getCallableFunction } from '../../backend'

const { Step } = Steps
const { Header, Content, Footer } = LayoutAnt

const Layout = ({ children, data, handleLogout, currentUser }) => {
	const [currentStep, setCurrentStep] = useState(0)
	const [loading, setLoading] = useState(false)
	const [isDone, setIsDone] = useState(false)

	const handlePrev = () => {
		setCurrentStep(c => c - 1)
		setLoading(false)
	}

	const handleNext = () => {
		setCurrentStep(c => c + 1)
	}

	const toBase64 = file =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = reject
		})

	const handleSave = async () => {
		const venueId = getVenueId()

		const { venue, spaces, sources, status, users, templates, customFields, formSetups, invoiceTarget, isUnknownSpace, isUnknownStatus, isUnknownCustomFields, isUnknownSources, isUnknownTemplates, showCGVInQuote, isGonnaUseQuote, isGonnaUseInvoice, isGonnaUseFrenchQI, isGonnaUseEnglishQI, integrations } = data

		setLoading(true)

		try {
			const reducedFilesArr = Object.keys(data.files)
				.filter(key => data.files[key])
				.reduce((res, key) => [...res, ...data.files[key].reduce((res, f) => [...res, { key, ...f }], [])], [])

			// const arr = reducedFilesArr.map(({ originFileObj }) => uploadFile(`files/${venueId}/${uuidv4()}`, originFileObj))
			// const files = await Promise.all(arr)
			// const urls = files.map((f, index) => ({ url: f.url, type: Object.keys(data.files)[index] }))

			const arrUint8 = await Promise.all(reducedFilesArr.map(({ originFileObj }) => toBase64(originFileObj)))
			const uploadFiles = getCallableFunction('uploadFiles')
			const {
				data: { result: resF },
			} = await uploadFiles({ baseURL: `files/${venueId}`, params: { etablissement: venueId, isGlobal: true }, files: reducedFilesArr.map(({ originFileObj }) => ({ name: originFileObj.name, type: originFileObj.type })), arrUint8 })

			const urls = resF.map((f, index) => ({ url: f.url, type: Object.keys(data.files)[index] }))

			const plaquetteIndexes = []
			reducedFilesArr.forEach(({ key }, index) => {
				if (key === 'plaquette') {
					plaquetteIndexes.push(index)
				}
			})

			const filesToAdd = plaquetteIndexes.reduce((res, index) => [...res, { name: resF[index].name, url: urls[index].url }], [])

			const html = `
			<p>Nom : ${venue.nom}</p>
			<p>
			<div>Fichiers</div>
			${urls.map(({ type, url }) => `<div>${type} : <a href="${url}" target="_blank">lien</a></div>`).join('')}
			</p>
			<p>
			<div>Demande d'aide</div>
			<div>Espaces : ${isUnknownSpace}</div>
			<div>Champs sur mesure : ${isUnknownCustomFields}</div>
			<div>Sources : ${isUnknownSources}</div>
			<div>Emails : ${isUnknownTemplates}</div>
			<div>Statuts : ${isUnknownStatus}</div>
			</p>
			<p>
			<div>Facturation</div>
			<div>Utilisation des devis : ${isGonnaUseQuote}</div>
			<div>Utilisation des factures : ${isGonnaUseInvoice}</div>
			<div>Devis/factures en français : ${isGonnaUseFrenchQI}</div>
			<div>Devis/factures en anglais : ${isGonnaUseEnglishQI}</div>
			<div>Faut-il implémenter les CGV dans le devis ? : ${showCGVInQuote}</div>
			<div>Si ce n'est pas vous, à qui devons nous adresser les factures ?</div>
			<div>${invoiceTarget.firstname || ''} ${invoiceTarget.lastname || ''} ${invoiceTarget.email || ''}</div>
			</p>
			<p>
			<div>Formulaire</div>
			${formSetups.map(v => `<div>${v}</div>`).join('')}
			</p>
			<p>
			${Object.keys(integrations).map(i => `<div>${i} : ${integrations[i]}</div>`).join('')}
			</p>
			`

			let logoURL
			if (data?.logoFile?.[0].originFileObj) {
				const arrUint8 = await Promise.all([toBase64(data.logoFile[0].originFileObj)])
				const uploadFiles = getCallableFunction('uploadFiles')
				const {
					data: { result: resF },
				} = await uploadFiles({ saveFiles: false, baseURL: `files/${venueId}`, params: { etablissement: venueId }, files: data.logoFile.map(({ originFileObj }) => ({ name: originFileObj.name, type: originFileObj.type })), arrUint8 })
				const urls = resF.map((f, index) => ({ url: f.url, type: Object.keys(data.files)[index] }))

				logoURL = urls[0].url
			}

			const params = { html, filesToAdd, logoURL, venue: { ...venue, conditionsAcompte: venue.conditionsFacture, conditionsAvoir: venue.conditionsFacture, conditionsSolde: venue.conditionsFacture, signatureEmail: `<p>${venue.signatureEmail || ' '}</p>` }, userId: currentUser.uid, spaces, users, sources, status, templates, customFields, venueId, isUnknownStatus, isUnknownSources, isUnknownTemplates }

			const createVenue = getCallableFunction('createVenue')
			await createVenue(params)

			setLoading(false)
			setIsDone(true)
			handleNext()
		} catch (error) {
			console.log(error)
			message.error('Oups ! Une erreur est survenue...')
			setLoading(false)
		}
	}

	return (
		<LayoutAnt style={{ height: '100vh' }}>
			<Header style={{ color: 'white', fontSize: 24, display: 'flex', justifyContent: 'center' }}>
				<div style={{ width: 1000, display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ width: 100 }}></div>
					<div>Création de compte</div>
					<div>
						<Button onClick={handleLogout}>Quitter</Button>
					</div>
				</div>
			</Header>
			<Content style={{ padding: '32px 64px 0px', display: 'flex', justifyContent: 'center' }}>
				<div style={{ display: 'flex', height: '100%', width: '100%' }}>
					<div style={{ width: 250, background: 'white', padding: 32, marginRight: 16, borderRadius: 4, overflow: 'scroll' }}>
						<Steps progressDot size="small" current={currentStep} direction="vertical">
							<Step title="Préambule" />
							<Step title="Informations générales" />
							<Step title="Utilisateurs" />
							<Step title="Formulaires" />
							<Step title="Espaces/Formats" />
							<Step title="Champs sur mesure" />
							<Step title="Statuts de suivi" />
							<Step title="Sources" />
							<Step title="Modèles d'emails" />
							<Step title="Devis et factures" />
							<Step title="Intégrations" />
							<Step title="Imports" />
							<Step title="Abonnement" />
						</Steps>
					</div>
					<div style={{ overflow: 'scroll', padding: 32, backgroundColor: 'white', flex: 1, borderRadius: 4 }}>{children(currentStep)}</div>
				</div>
			</Content>
			{!isDone && (
				<Footer style={{ display: 'flex', justifyContent: 'center' }}>
					<div style={{ width: 1000, display: 'flex', justifyContent: 'flex-end' }}>
						<div>
							{currentStep > 0 && (
								<Button onClick={handlePrev} style={{ marginRight: 32 }}>
									Précédent
								</Button>
							)}
							{currentStep === 12 ? (
								<Button loading={loading} onClick={handleSave} type="primary">
									Envoyer
								</Button>
							) : (
								<Button onClick={handleNext} type="primary">
									Suivant
								</Button>
							)}
						</div>
					</div>
				</Footer>
			)}
		</LayoutAnt>
	)
}

export default Layout
