import { useState, useEffect } from 'react'
import { Modal, Input } from 'antd'
import Section from '../Utils/Section'
import { v4 as uuidv4 } from 'uuid'
import { HexColorPicker, HexColorInput } from 'react-colorful'

const StatusModal = ({ open, handleCancel, data, handleSave }) => {
	const [status, setStatus] = useState({ id: uuidv4() })

	useEffect(() => {
		if (data) {
			setStatus(data)
		}
	}, [data])

	const handleChange = attr => e => {
		setStatus(u => ({ ...u, [attr]: e.target.value }))
	}

	const handleChangeColor = attr => value => {
		setStatus(u => ({ ...u, [attr]: value }))
	}

	const onOk = () => {
		handleSave(status)
	}

	return (
		<Modal title="Statut" open={open} onCancel={handleCancel} onOk={onOk} okText="Valider">
			<Section title="Nom">
				<Input placeholder="Nom" value={status?.name} onChange={handleChange('name')} />
			</Section>
			<Section title="Ordre">
				<Input placeholder="Ordre" value={status?.order} onChange={handleChange('order')} />
			</Section>
			<Section title="Couleur">
				<HexColorPicker color={status?.color} onChange={handleChangeColor('color')} />
				<HexColorInput prefixed alpha className="ant-input" style={{ width: 200, marginTop: 4 }} color={status?.color} onChange={handleChangeColor('color')} />
			</Section>
		</Modal>
	)
}

export default StatusModal
