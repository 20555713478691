import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Select } from 'antd'

const { Option } = Select

const TimeSelect = ({ startTime, endTime, value, onSelect, allowClear = false }) => {
	const [hoursArray, setHoursArray] = useState([])

	useEffect(() => {
		const startTimeMoment = dayjs(startTime, 'HH:mm')
		const endTimeMoment = dayjs(endTime, 'HH:mm').diff(startTimeMoment) < 0 ? dayjs(endTime, 'HH:mm').add(1, 'day') : dayjs(endTime, 'HH:mm')

		const _hoursArray = []
		for (let m = startTimeMoment; m.diff(endTimeMoment, 'minute') <= 0; m = m.add(15, 'minute')) {
			_hoursArray.push({ label: m.format('HH:mm'), value: m.format('HH:mm') })
		}
		setHoursArray(_hoursArray)
	}, [startTime, endTime])

	return (
		<Select size='small' allowClear={allowClear} style={{ width: '100%' }} showSearch placeholder="Heure" value={value || []} onChange={onSelect} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
			{hoursArray.map(({ value, label }) => (
				<Option value={value} key={value}>
					{label}
				</Option>
			))}
		</Select>
	)
}

export default TimeSelect
