import { useState, useEffect } from 'react'
import { Modal, Input } from 'antd'
import Section from '../Utils/Section'
import { v4 as uuidv4 } from 'uuid'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const TemplateModal = ({ open, handleCancel, data, handleSave }) => {
	const [template, setTemplate] = useState({ id: uuidv4() })
	const [editorState, setEditorState] = useState(EditorState.createEmpty())

	useEffect(() => {
		if (data) {
			setTemplate(data)
		}
	}, [data])

	useEffect(() => {
		if (data?.content) {
			const contentBlock = htmlToDraft(data.content)
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
				setEditorState(EditorState.createWithContent(contentState))
			}
		}
	}, [data?.content])

	const onEditorStateChange = value => {
		setEditorState(value)
	}

	const handleChange =
		(attr, type = 'input') =>
		e => {
			switch (type) {
				case 'input':
					setTemplate(u => ({ ...u, [attr]: e.target.value }))
					break
				case 'value':
					setTemplate(u => ({ ...u, [attr]: e }))
					break
				default:
					break
			}
		}

	const onOk = () => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
		handleSave({ ...template, content: html })
	}

	return (
		<Modal title="Utilisateur" open={open} onCancel={handleCancel} onOk={onOk} okText="Valider">
			<Section title="Nom">
				<Input placeholder="Nom" value={template?.nom} onChange={handleChange('nom')} />
			</Section>
			<Section title="Objet">
				<Input placeholder="Objet" value={template?.objet} onChange={handleChange('objet')} />
			</Section>
			<Section title="Contenu">
				<Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
			</Section>
		</Modal>
	)
}

export default TemplateModal
