export const mappingTypes = () => [
	{ label: 'Liste choix unique', value: 'select' },
	{ label: 'Liste choix multiple', value: 'multi-select' },
	{ label: 'Texte court', value: 'input' },
	{ label: 'Texte long', value: 'textarea' },
]
export const mappingCollection = () => [
	{ label: 'Fiche client', value: 'clients' },
	{ label: 'Fiche compte', value: 'accounts' },
	{ label: 'Fiche évènement', value: 'reservations' },
]
