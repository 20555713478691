import { useState, useEffect } from 'react'
import { Modal, Input } from 'antd'
import Section from '../Utils/Section'
import { v4 as uuidv4 } from 'uuid'

const SourceModal = ({ open, handleCancel, data, handleSave }) => {
	const [source, setSource] = useState({ id: uuidv4() })

	useEffect(() => {
		if (data) {
			setSource(data)
		}
	}, [data])

	const handleChange = attr => e => {
		setSource(u => ({ ...u, [attr]: e.target.value }))
	}

	const onOk = () => {
		handleSave(source)
	}

	return (
		<Modal title="Sources" open={open} onCancel={handleCancel} onOk={onOk} okText="Valider">
			<Section title="Nom">
				<Input placeholder="Nom" value={source?.name} onChange={handleChange('name')} />
			</Section>
		</Modal>
	)
}

export default SourceModal
