import { useState, useEffect } from 'react'
import { Modal, Input } from 'antd'
import Section from '../Utils/Section'
import { v4 as uuidv4 } from 'uuid'

const SpaceModal = ({ open, handleCancel, data, handleSave }) => {
	const [space, setSpace] = useState({ id: uuidv4() })

	useEffect(() => {
		if (data) {
			setSpace(data)
		}
	}, [data])

	const handleChange = attr => e => {
		setSpace(u => ({ ...u, [attr]: e.target.value }))
	}

	const onOk = () => {
		handleSave(space)
	}

	return (
		<Modal title="Espace/Format" open={open} onCancel={handleCancel} onOk={onOk} okText="Valider">
			<Section title="Nom">
				<Input placeholder="Nom" value={space?.name} onChange={handleChange('name')} />
			</Section>
		</Modal>
	)
}

export default SpaceModal
