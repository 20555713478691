import { useState, useEffect } from 'react'
import { Modal, Input, Select } from 'antd'
import Section from '../Utils/Section'
import { v4 as uuidv4 } from 'uuid'

const UserModal = ({ open, handleCancel, data, handleSave }) => {
	const [user, setUser] = useState({ id: uuidv4() })

	useEffect(() => {
		if (data) {
			setUser(data)
		}
	}, [data])

	const handleChange =
		(attr, type = 'input') =>
		e => {
			switch (type) {
				case 'input':
					setUser(u => ({ ...u, [attr]: e.target.value }))
					break
				case 'value':
					setUser(u => ({ ...u, [attr]: e }))
					break
				default:
					break
			}
		}

	const onOk = () => {
		handleSave(user)
	}

	return (
		<Modal title="Utilisateur" open={open} onCancel={handleCancel} onOk={onOk} okText="Valider">
			<Section title="Prénom">
				<Input placeholder="Prénom" value={user?.prenom} onChange={handleChange('prenom')} />
			</Section>
			<Section title="Nom">
				<Input placeholder="Nom" value={user?.nom} onChange={handleChange('nom')} />
			</Section>
			<Section title="Email">
				<Input placeholder="Email" value={user?.email} onChange={handleChange('email')} />
			</Section>
			<Section title="Poste">
				<Input placeholder="Poste" value={user?.job} onChange={handleChange('job')} />
			</Section>
			<Section title="Accès">
				<Select
					value={user?.type}
					onChange={handleChange('type', 'value')}
					placeholder="Accès"
					style={{ width: '100%' }}
					options={[
						{ value: 'staff', label: 'Lecteur' },
						{ value: 'manager', label: 'Editeur' },
					]}
				/>
			</Section>
		</Modal>
	)
}

export default UserModal
