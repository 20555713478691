import { useState, useEffect } from 'react'
import LoginView from './Login'
import App from './App'
import { app } from '../backend'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'

const Main = () => {
	const [isLogged, setIsLogged] = useState(false)
	const [currentUser, setCurrentUser] = useState()

	const auth = getAuth(app)

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async user => {
			if (user) {
				setIsLogged(true)
				setCurrentUser(user)
			} else {
				setIsLogged(false)
			}
		})
		return () => {
			unsubscribe()
		}
	}, [])

	const handleLogout = async () => {
		await signOut(auth)
	}

	return <>{isLogged ? <App currentUser={currentUser} handleLogout={handleLogout} /> : <LoginView />}</>
}

export default Main
