import { useState } from 'react'
import { Form, Input, Button, Layout, message, Typography } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { app } from '../../backend'
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth'

const { Title, Text } = Typography

const Login = () => {
	const [loading, setLoading] = useState(false)

	const auth = getAuth(app)

	const onFinish = values => {
		setLoading(true)
		login(values)
	}

	const login = values => {
		signInWithEmailAndPassword(auth, values.email.trim(), values.password.trim())
			.then(() => {
				setLoading(false)
			})
			.catch(error => {
				setLoading(false)
				console.log(error)
				message.error('Oups !')
			})
	}

	return (
		<Layout>
			<div style={{ height: '100vh', display: 'flex' }}>
				<div style={{ backgroundColor: 'white', width: '100vw' }}>
					<div style={{ width: 800, padding: '64px 128px', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
						<div className="primary-color " style={{ fontSize: 21, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
							BookingShake
						</div>
						<div>
							<Title level={2}>Création de compte</Title>
							<Text type="secondary">Renseignez vos identifiants</Text>
							<Form layout="vertical" name="normal_login" initialValues={{ remember: true }} onFinish={onFinish} style={{ marginTop: 32 }}>
								<Form.Item label="Email" name="email" rules={[{ required: true, message: 'Email ?' }]}>
									<Input size="large" prefix={<MailOutlined />} placeholder="Email" />
								</Form.Item>
								<Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: 'Mot de passe ?' }]}>
									<Input size="large" prefix={<LockOutlined />} type="password" placeholder="Mot de passe" />
								</Form.Item>

								<Form.Item>
									<Button size="large" loading={loading} type="primary" htmlType="submit" style={{ width: '100%', marginTop: 32 }}>
										VALIDER
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
							<Text type="secondary">© 2022 Atelier Business Food. Tous droits réservés.</Text>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Login
