import { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const TextEditorModal = ({ open, handleCancel, handleSave, data }) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty())

	useEffect(() => {
		if (data) {
			const contentBlock = htmlToDraft(data)
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
				setEditorState(EditorState.createWithContent(contentState))
			}
		}
	}, [data])

	const onEditorStateChange = value => {
		setEditorState(value)
	}

	const onOk = () => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
		handleSave(html)
	}

	return (
		<Modal title="Text Editor" open={open} onCancel={handleCancel} okText="Valider" onOk={onOk}>
			<Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
		</Modal>
	)
}

export default TextEditorModal
