import { Typography } from 'antd'

const { Text } = Typography

const Section = ({ children, title, addon }) => {
	return (
		<div style={{ marginBottom: 16 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
				<Text type="secondary" style={{ display: 'block', fontSize: 12 }}>
					{title}
				</Text>
				<div>{addon}</div>
			</div>
			<div>{children}</div>
		</div>
	)
}

export default Section
