import { useState, useEffect } from 'react'
import { Modal, Input, Select, Button, Tag } from 'antd'
import Section from '../Utils/Section'
import { v4 as uuidv4 } from 'uuid'
import { mappingCollection, mappingTypes } from '../../constants'

const CustomFieldsModal = ({ open, handleCancel, data, handleSave }) => {
	const [customField, setCustomField] = useState({ id: uuidv4() })
	const [valueToAdd, setValueToAdd] = useState(null)

	useEffect(() => {
		if (data) {
			setCustomField(data)
		}
	}, [data])

	const handleChange =
		(attr, type = 'input') =>
		e => {
			switch (type) {
				case 'input':
					setCustomField(u => ({ ...u, [attr]: e.target.value }))
					break
				case 'value':
					setCustomField(u => ({ ...u, [attr]: e }))
					break
				case 'addToArray':
					setCustomField(u => ({ ...u, [attr]: [...(u[attr] || []), e] }))
					break
				default:
					break
			}
		}

	const onOk = () => {
		handleSave(customField)
	}

	const handleAddValue = () => {
		if (valueToAdd) {
			handleChange('data', 'addToArray')(valueToAdd)
			setValueToAdd(null)
		}
	}

	const handleRemoveValue = value => () => {
		const _data = customField.data.filter(_value => _value !== value)
		handleChange('data', 'value')(_data)
		setValueToAdd(null)
	}

	const handleChangeValueToAdd = e => {
		setValueToAdd(e.target.value)
	}

	return (
		<Modal title="Champ sur mesure" open={open} onCancel={handleCancel} onOk={onOk} okText="Valider">
			<Section title="Nom">
				<Input placeholder="Nom" value={customField?.name} onChange={handleChange('name')} />
			</Section>
			<Section title="Affectation">
				<Select placeholder="Affectation" style={{ width: '100%' }} value={customField?.collection} onChange={handleChange('collection', 'value')} options={mappingCollection()} />
			</Section>
			<Section title="Type">
				<Select placeholder="Type" style={{ width: '100%' }} value={customField?.type} onChange={handleChange('type', 'value')} options={mappingTypes()} />
			</Section>
			{(customField?.type === 'select' || customField?.type === 'multi-select') && (
				<Section title="Liste">
					<div style={{ display: 'flex', marginBottom: 8 }}>
						<Input size="small" value={valueToAdd} onChange={handleChangeValueToAdd} placeholder="Ecrivez ici..." />
						<Button type="primary" size="small" onClick={handleAddValue} style={{ marginLeft: 8 }}>
							Ajouter
						</Button>
					</div>
					{customField?.data?.map(value => (
						<Tag key={value} closable onClose={handleRemoveValue(value)}>
							{value}
						</Tag>
					))}
				</Section>
			)}
		</Modal>
	)
}

export default CustomFieldsModal
