import { initializeApp } from 'firebase/app'
import { initializeFirestore, doc, collection } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

export const app = initializeApp(config)

// window.FIREBASE_APPCHECK_DEBUG_TOKEN = 'cd0b3b8d-a487-45c6-9aec-295e06e112cb'


initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider('6LcIVtccAAAAAG79NGEE_Rv-Gh82dXAig3j9vbLz'),
	isTokenAutoRefreshEnabled: true,
})


export const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true })
export const storage = getStorage(app)
const functions = getFunctions(app)

export const getCallableFunction = functionName => {
	return httpsCallable(functions, functionName)
}

export const uploadFile = async (path, file) => {
	const storageRef = ref(storage, path)
	const snapshot = await uploadBytes(storageRef, file)
	const url = await getDownloadURL(snapshot.ref)
	return { url, name: snapshot.metadata.name }
}

export const getVenueId = () => doc(collection(firestore, 'etablissements')).id
