import { useState } from 'react'
import { Input, Upload, Switch, Button, Table, Alert, Typography, Checkbox, Tag, InputNumber, Select, message } from 'antd'
import { DeleteOutlined, PlusOutlined, WarningFilled } from '@ant-design/icons'
import Layout from './Layouts/Layout'
import Section from './Utils/Section'
import TimeSelect from './Utils/TimeSelect'
import TextEditorModal from './Modals/TextEditorModal'
import UserModal from './Modals/UserModal'
import SpaceModal from './Modals/SpaceModal'
import SourceModal from './Modals/SourceModal'
import StatusModal from './Modals/StatusModal'
import CustomFieldsModal from './Modals/CustomFieldsModal'
import { mappingTypes } from '../constants'
import TemplateModal from './Modals/TemplateModal'

const { Title, Text } = Typography

const App = ({ currentUser, handleLogout }) => {
	const [logoFile, setLogoFile] = useState()
	const [files, setFiles] = useState({ plaquette: null, catalog: null, function: null, cgv: null, invoiceExample: null, quoteExample: null, db: null, signedQuote: null })
	const [invoiceTarget, setInvoiceTarget] = useState({ firstname: null, lastname: null, email: null })
	const [users, setUsers] = useState([])
	const [spaces, setSpaces] = useState([])
	const [sources, setSources] = useState([])
	const [status, setStatus] = useState([])
	const [templates, setTemplates] = useState([])
	const [customFields, setCustomFields] = useState([])
	const [formSetups, setFormSetups] = useState([])
	const [venue, setVenue] = useState({
		semaine: {
			lundi: { ouvert: true, heureOuverture: '00:00', heureFermeture: '23:45' },
			mardi: { ouvert: true, heureOuverture: '00:00', heureFermeture: '23:45' },
			mercredi: { ouvert: true, heureOuverture: '00:00', heureFermeture: '23:45' },
			jeudi: { ouvert: true, heureOuverture: '00:00', heureFermeture: '23:45' },
			vendredi: { ouvert: true, heureOuverture: '00:00', heureFermeture: '23:45' },
			samedi: { ouvert: true, heureOuverture: '00:00', heureFermeture: '23:45' },
			dimanche: { ouvert: true, heureOuverture: '00:00', heureFermeture: '23:45' },
		},
		currency: 'EUR',
		defaultLang: 'fr',
		montlhyViewNameType: 'account_name',
		conditionsFacture: "Escompte pour paiement anticipé : néant. Des pénalités correspondant à 3 fois le taux de l'intérêt légal seront applicables en l'absence ou en cas de retard de paiement. Indemnité forfaitaire de 40€ pour frais de recouvrement, en cas de retard de paiement.",
	})
	const [integrations, setIntegrations] = useState({ googleGmail: false, googleCalendar: false, microsoftOutlook: false, stripe: false, zenchef: false, guestonline: false, mews: false, thais: false })

	const [showVenueCalendar, setShowVenueCalendar] = useState(false)
	const [isUnknownSpace, setIsUnknownSpace] = useState(false)
	const [isUnknownStatus, setIsUnknownStatus] = useState(false)
	const [isUnknownCustomFields, setIsUnknownCustomFields] = useState(false)
	const [isUnknownSources, setIsUnknownSources] = useState(false)
	const [isUnknownTemplates, setIsUnknownTemplates] = useState(false)
	const [isGonnaUseQuote, setIsGonnaUseQuote] = useState(false)
	const [isGonnaUseInvoice, setIsGonnaUseInvoice] = useState(false)
	const [isGonnaUseFrenchQI, setIsGonnaUseFrenchQI] = useState(false)
	const [isGonnaUseEnglishQI, setIsGonnaUseEnglishQI] = useState(false)
	const [showCGVInQuote, setShowCGVInQuote] = useState()

	const [isTextEditorModalVisible, setIsTextEditorModalVisible] = useState(false)
	const [isUserModalVisible, setIsUserModalVisible] = useState(false)
	const [isSpaceModalVisible, setIsSpaceModalVisible] = useState(false)
	const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false)
	const [isSourceModalVisible, setIsSourceModalVisible] = useState(false)
	const [isStatusModalVisible, setIsStatusModalVisible] = useState(false)
	const [isCustomFieldsModalVisible, setIsCustomFieldsModalVisible] = useState(false)

	const [selectedUserId, setSelectedUserId] = useState()
	const [selectedTemplateId, setSelectedTemplateId] = useState()
	const [selectedSpaceId, setSelectedSpaceId] = useState()
	const [selectedSourceId, setSelectedSourceId] = useState()
	const [selectedStatusId, setSelectedStatusId] = useState()
	const [selectedCustomFieldId, setSelectedCustomFieldId] = useState()

	const handleChangeInvoiceTarget = attr => e => {
		setInvoiceTarget(v => ({ ...v, [attr]: e.target.value }))
	}

	const toggleShowVenueCalendar = () => {
		setShowVenueCalendar(v => !v)
	}

	const handleShowCGVInQuote = v => () => {
		setShowCGVInQuote(v)
	}

	const handleChangeFormSetup = checkedValues => {
		setFormSetups(checkedValues)
	}

	const handleChangeIsUnknownSpace = () => {
		setIsUnknownSpace(v => !v)
	}

	const handleChangeIsUnknownCustomFields = () => {
		setIsUnknownCustomFields(v => !v)
	}

	const handleChangeIsUnknownStatus = () => {
		setIsUnknownStatus(v => !v)
	}

	const handleChangeIsUnknownSources = () => {
		setIsUnknownSources(v => !v)
	}

	const handleChangeIsUnknownTemplates = () => {
		setIsUnknownTemplates(v => !v)
	}

	const handleChangeIsGonnaUseQuote = () => {
		setIsGonnaUseQuote(v => !v)
	}

	const handleChangeIsGonnaUseInvoice = () => {
		setIsGonnaUseInvoice(v => !v)
	}

	const handleChangeIsGonnaUseFrenchQI = () => {
		setIsGonnaUseFrenchQI(v => !v)
	}

	const handleChangeIsGonnaUseEnglishQI = () => {
		setIsGonnaUseEnglishQI(v => !v)
	}

	const handleChangeVenue =
		(attr, type = 'input') =>
		e => {
			switch (type) {
				case 'input':
					setVenue(v => ({ ...v, [attr]: e.target.value }))
					break
				case 'value':
					setVenue(v => ({ ...v, [attr]: e }))
					break
				default:
					break
			}
		}

	const handleChangeTable = (day, attr) => e => {
		setVenue(v => ({ ...v, semaine: { ...v.semaine, [day]: { ...(v?.semaine?.[day] || {}), [attr]: e } } }))
	}

	const handleCancelTextEditorModal = () => {
		setIsTextEditorModalVisible(false)
	}

	const handleOpenTextEditorModal = () => {
		setIsTextEditorModalVisible(true)
	}

	const handleCancelUserModal = () => {
		setIsUserModalVisible(false)
		setSelectedUserId()
	}

	const handleOpenUserModal = () => {
		setIsUserModalVisible(true)
	}

	const handleCancelSpaceModal = () => {
		setIsSpaceModalVisible(false)
		setSelectedSpaceId()
	}

	const handleOpenSpaceModal = () => {
		setIsSpaceModalVisible(true)
	}

	const handleCancelSourceModal = () => {
		setIsSourceModalVisible(false)
		setSelectedSourceId()
	}

	const handleOpenSourceModal = () => {
		setIsSourceModalVisible(true)
	}

	const handleCancelTemplateModal = () => {
		setIsTemplateModalVisible(false)
		setSelectedTemplateId()
	}

	const handleOpenTemplateModal = () => {
		setIsTemplateModalVisible(true)
	}

	const handleCancelStatusModal = () => {
		setIsStatusModalVisible(false)
		setSelectedStatusId()
	}

	const handleOpenStatusModal = () => {
		setIsStatusModalVisible(true)
	}

	const handleCancelCustomFieldModal = () => {
		setIsCustomFieldsModalVisible(false)
		setSelectedCustomFieldId()
	}

	const handleOpenCustomFieldModal = () => {
		setIsCustomFieldsModalVisible(true)
	}

	const handleChangeSignatureEmail = html => {
		setVenue(v => ({ ...v, signatureEmail: html }))
		handleCancelTextEditorModal()
	}

	const handleSelectedUser = id => {
		setSelectedUserId(id)
		handleOpenUserModal()
	}

	const handleSelectedSpace = id => {
		setSelectedSpaceId(id)
		handleOpenSpaceModal()
	}

	const handleSelectedCustomField = id => {
		setSelectedCustomFieldId(id)
		handleOpenCustomFieldModal()
	}

	const handleSelectedSource = id => {
		setSelectedSourceId(id)
		handleOpenSourceModal()
	}

	const handleSelectedStatus = id => {
		setSelectedStatusId(id)
		handleOpenStatusModal()
	}

	const handleSelectedTemplate = id => {
		setSelectedTemplateId(id)
		handleOpenTemplateModal()
	}

	const handleSaveUser = user => {
		if (users.find(({ id }) => id === user.id)) {
			setUsers(u =>
				u.map(_u => {
					if (_u.id === user.id) {
						return user
					}
					return _u
				})
			)
		} else {
			setUsers(u => [...u, user])
		}
		handleCancelUserModal()
	}

	const handleSaveSpace = space => {
		if (spaces.find(({ id }) => id === space.id)) {
			setSpaces(s =>
				s.map(_s => {
					if (_s.id === space.id) {
						return space
					}
					return _s
				})
			)
		} else {
			setSpaces(s => [...s, space])
		}
		handleCancelSpaceModal()
	}

	const handleSaveCustomField = customField => {
		if (customFields.find(({ id }) => id === customField.id)) {
			setCustomFields(s =>
				s.map(_s => {
					if (_s.id === customField.id) {
						return customField
					}
					return _s
				})
			)
		} else {
			setCustomFields(s => [...s, customField])
		}
		handleCancelCustomFieldModal()
	}

	const handleSaveSource = source => {
		if (sources.find(({ id }) => id === source.id)) {
			setSources(s =>
				s.map(_s => {
					if (_s.id === source.id) {
						return source
					}
					return _s
				})
			)
		} else {
			setSources(s => [...s, source])
		}
		handleCancelSourceModal()
	}

	const handleSaveStatus = _status => {
		if (status.find(({ id }) => id === _status.id)) {
			setStatus(s =>
				s.map(_s => {
					if (_s.id === _status.id) {
						return _status
					}
					return _s
				})
			)
		} else {
			setStatus(s => [...s, _status])
		}
		handleCancelStatusModal()
	}

	const handleSaveTemplate = template => {
		if (templates.find(({ id }) => id === template.id)) {
			setTemplates(s =>
				s.map(_s => {
					if (_s.id === template.id) {
						return template
					}
					return _s
				})
			)
		} else {
			setTemplates(s => [...s, template])
		}
		handleCancelTemplateModal()
	}

	// const beforeUploadImage = file => {
	// 	const isCorrect = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'

	// 	if (!isCorrect) {
	// 		message.error(`${file.name} doit être en png ou jpg`)
	// 	}

	// 	return isCorrect || Upload.LIST_IGNORE
	// }

	const handleChangeLogo = ({ fileList: newFileList }) => {
		if (newFileList?.length > 0) {
			setLogoFile(newFileList)
		} else {
			setLogoFile(null)
		}
	}

	const handleChangeFile =
		type =>
		({ fileList: newFileList }) => {
			if (newFileList?.length > 0) {
				setFiles(f => ({ ...f, [type]: newFileList }))
			} else {
				setFiles(f => ({ ...f, [type]: null }))
			}
		}

	const handleDeleteSpace = spaceId => e => {
		e.stopPropagation()
		setSpaces(s => s.filter(({ id }) => id !== spaceId))
	}

	const handleDeleteCustomField = customFieldId => e => {
		e.stopPropagation()
		setCustomFields(s => s.filter(({ id }) => id !== customFieldId))
	}

	const handleDeleteUser = userId => e => {
		e.stopPropagation()
		setUsers(s => s.filter(({ id }) => id !== userId))
	}

	const handleDeleteSource = sourceId => e => {
		e.stopPropagation()
		setSources(s => s.filter(({ id }) => id !== sourceId))
	}

	const handleDeleteStatus = statusId => e => {
		e.stopPropagation()
		setStatus(s => s.filter(({ id }) => id !== statusId))
	}

	const handleDeleteTemplate = templateId => e => {
		e.stopPropagation()
		setTemplates(s => s.filter(({ id }) => id !== templateId))
	}

	const handleIntegration = v => () => {
		setIntegrations(d => ({ ...d, [v]: !d[v] }))
	}

	return (
		<>
			{isTemplateModalVisible && <TemplateModal open={isTemplateModalVisible} handleCancel={handleCancelTemplateModal} data={selectedTemplateId && templates.find(({ id }) => id === selectedTemplateId)} handleSave={handleSaveTemplate} />}
			{isTextEditorModalVisible && <TextEditorModal open={isTextEditorModalVisible} handleCancel={handleCancelTextEditorModal} handleSave={handleChangeSignatureEmail} data={venue?.signatureEmail} />}
			{isUserModalVisible && <UserModal open={isUserModalVisible} handleCancel={handleCancelUserModal} data={selectedUserId && users.find(({ id }) => id === selectedUserId)} handleSave={handleSaveUser} />}
			{isSpaceModalVisible && <SpaceModal open={isSpaceModalVisible} handleCancel={handleCancelSpaceModal} data={selectedSpaceId && spaces.find(({ id }) => id === selectedSpaceId)} handleSave={handleSaveSpace} />}
			{isCustomFieldsModalVisible && <CustomFieldsModal open={isCustomFieldsModalVisible} handleCancel={handleCancelCustomFieldModal} data={selectedCustomFieldId && customFields.find(({ id }) => id === selectedCustomFieldId)} handleSave={handleSaveCustomField} />}
			{isSourceModalVisible && <SourceModal open={isSourceModalVisible} handleCancel={handleCancelSourceModal} data={selectedSourceId && sources.find(({ id }) => id === selectedSourceId)} handleSave={handleSaveSource} />}
			{isStatusModalVisible && <StatusModal open={isStatusModalVisible} handleCancel={handleCancelStatusModal} data={selectedStatusId && status.find(({ id }) => id === selectedStatusId)} handleSave={handleSaveStatus} />}
			<Layout currentUser={currentUser} handleLogout={handleLogout} data={{ logoFile, files, spaces, sources, status, venue, users, templates, customFields, formSetups, invoiceTarget, isUnknownSpace, isUnknownStatus, isUnknownCustomFields, isUnknownSources, isUnknownTemplates, showCGVInQuote, isGonnaUseQuote, isGonnaUseInvoice, isGonnaUseFrenchQI, isGonnaUseEnglishQI, integrations }}>
				{currentStep => (
					<>
						{currentStep === 0 && (
							<>
								<Title level={3}>Bienvenue dans votre formulaire de création de compte</Title>
								<div style={{ fontWeight: 'bold', marginTop: 16 }}>
									<WarningFilled style={{ marginRight: 8, color: '#f1c40f' }} />
									Attention une fois lancé, vous devez aller jusqu'au bout.
									<WarningFilled style={{ marginLeft: 8, color: '#f1c40f' }} />
								</div>
								<div style={{ marginTop: 32 }}>Ne vous inquiétez pas Clémence vous accompagne à chaque étape de ce formulaire avec ses conseils...</div>
								<Alert
									style={{ marginTop: 32 }}
									message={<div style={{ fontWeight: 'bold' }}>1er Conseil de Clémence</div>}
									description={
										<div>
											<div>Pour plus de confort lors du remplissage, n'hésitez pas à préparer les PJ suivantes :</div>
											<ul>
												<li>votre logo</li>
												<li>plaquette(s) commerciale(s)</li>
												<li>vos modèles d'email utilisé pour vos clients</li>
												<li>catalogue de prix/service pour import</li>
												<li>un ou des exemples de fiche de fonction / feuille de route opérationnelle</li>
												<li>votre Kbis et RIB</li>
												<li>un ou des exemples de vos devis et factures actuels envoyés à vos clients</li>
												<li>vos tableaux de base de données clients</li>
											</ul>
										</div>
									}
									type="info"
									showIcon
								/>
							</>
						)}
						{currentStep === 1 && (
							<>
								<Title level={4}>Commençons par le commencement...</Title>
								<Section title="Quel est le nom de votre établissement ?">
									<Input size="small" placeholder="Nom de l'établissement" style={{ width: '100%' }} value={venue?.nom} onChange={handleChangeVenue('nom')} />
								</Section>
								<Section title="Quelle est l'adresse de votre site internet ?">
									<Input size="small" placeholder="Site web de l'établissement" style={{ width: '100%' }} value={venue?.website} onChange={handleChangeVenue('website')} />
								</Section>
								<Section title="Quelles sont les coordonnées officielles de votre établissement pour vos clients ?">
									<Text type="secondary" style={{ fontSize: 12 }}>
										Adresse, téléphone, email... Vous pouvez copier-coller ici la signature email de votre service.
									</Text>
									<Input.TextArea autoSize size="small" placeholder="Coordonnées officielles de votre établissement" style={{ width: '100%' }} value={venue?.signatureEmail} onChange={handleChangeVenue('signatureEmail')} />
								</Section>
								{/* <Section title="Signature email">{venue?.signatureEmail ? <div onClick={handleOpenTextEditorModal} style={{ cursor: 'pointer', padding: 4, backgroundColor: 'white', borderRadius: 4, border: '1px solid lightgrey' }} dangerouslySetInnerHTML={{ __html: venue?.signatureEmail }} /> : <Button onClick={handleOpenTextEditorModal} type="dashed" icon={<PlusOutlined />}></Button>}</Section> */}
								<Section title="Pouvez-vous accueillir des événements et des groupes 7j/7, 24h/24 ?">
									<Switch size="small" checked={!showVenueCalendar} onChange={toggleShowVenueCalendar} />
								</Section>
								{showVenueCalendar && (
									<Section title="Merci de préciser vos jours et horaires d'ouverture dans le planning suivant :">
										<div style={{ fontSize: 12 }}>
											<div style={{ display: 'flex', marginBottom: 4, alignItems: 'center', fontWeight: 'bold' }}>
												<div style={{ flex: 1, marginRight: 16 }}>Jours</div>
												<div style={{ flex: 1 }}>Ouvert/Fermé</div>
												<div style={{ flex: 1, marginRight: 16 }}>Ouverture</div>
												<div style={{ flex: 1, marginRight: 16 }}>Fermeture</div>
											</div>
											{['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'].map(day => (
												<div key={day} style={{ display: 'flex', marginBottom: 4, alignItems: 'center' }}>
													<div style={{ flex: 1, marginRight: 16 }}>{day}</div>
													<div style={{ flex: 1 }}>
														<Switch size="small" checked={venue?.semaine?.[day]?.ouvert} onChange={handleChangeTable(day, 'ouvert')} />
													</div>
													<div style={{ flex: 1, marginRight: 16 }}>{venue?.semaine?.[day]?.ouvert && <TimeSelect startTime="00:00" endTime="23:45" value={venue?.semaine?.[day]?.heureOuverture} onSelect={handleChangeTable(day, 'heureOuverture')} />}</div>
													<div style={{ flex: 1, marginRight: 16 }}>{venue?.semaine?.[day]?.ouvert && <TimeSelect startTime="00:00" endTime="23:45" value={venue?.semaine?.[day]?.heureFermeture} onSelect={handleChangeTable(day, 'heureFermeture')} />}</div>
												</div>
											))}
										</div>
									</Section>
								)}
								<Section title="Accueillez-vous des événements résidentiels ?">
									<Switch size="small" checked={venue?.isRoomsActivated} onChange={handleChangeVenue('isRoomsActivated', 'value')} />
								</Section>
								<Section title="Pouvez-vous nous joindre votre logo en png ou jpg ?">
									<Upload onChange={handleChangeLogo} listType="picture-card" fileList={logoFile} beforeUpload={() => false}>
										{!logoFile && (
											<div>
												<PlusOutlined />
											</div>
										)}
									</Upload>
								</Section>
							</>
						)}
						{currentStep === 2 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Ajoutez ici les futurs utilisateurs de votre espace Booking Shake
								</Title>
								<Section
									addon={
										<Button type="primary" onClick={handleOpenUserModal} icon={<PlusOutlined />}>
											Ajouter un utilisateur
										</Button>
									}
								>
									<Table
										style={{ marginTop: 16 }}
										bordered
										locale={{ emptyText: 'Pas de donnée' }}
										rowKey="id"
										size="small"
										pagination={false}
										dataSource={users}
										columns={[
											{ title: 'Prénom', dataIndex: 'prenom' },
											{ title: 'Nom', dataIndex: 'nom' },
											{ title: 'Poste', dataIndex: 'job' },
											{ title: 'Email', dataIndex: 'email' },
											{ key: 'actions', width: 50, render: (_, record) => <Button onClick={handleDeleteUser(record.id)} type="link" danger icon={<DeleteOutlined />}></Button> },
										]}
										onRow={record => {
											return {
												onClick: () => {
													handleSelectedUser(record.id)
												},
											}
										}}
									/>
								</Section>
							</>
						)}
						{currentStep === 3 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Quel/s type/s de formulaire souhaitez-vous connecter à votre espace Booking Shake ?
								</Title>
								<Section title="Plusieurs sélections sont possibles.">
									<Checkbox.Group
										style={{ display: 'flex', flexDirection: 'column' }}
										options={[
											{ value: 'simpleForm', label: 'Formulaire de demande Booking Shake' },
											{ value: 'quoteForm', label: 'Formulaire de devis en ligne Booking Shake' },
											{ value: 'apiForm', label: 'Connexion du formulaire de mon site à Booking Shake (API)' },
											{ value: 'unknown', label: "Je n'ai pas encore décidé" },
										]}
										value={formSetups}
										onChange={handleChangeFormSetup}
									/>
								</Section>
							</>
						)}
						{currentStep === 4 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Quelle est la liste de vos espaces dans votre établissement ?
								</Title>
								<Section
									title={
										<Checkbox checked={isUnknownSpace} onChange={handleChangeIsUnknownSpace}>
											Je ne sais pas comment répondre à cette question
										</Checkbox>
									}
									addon={
										<Button onClick={handleOpenSpaceModal} type="primary" icon={<PlusOutlined />}>
											Ajouter un espace ou format
										</Button>
									}
								>
									<Table
										style={{ marginTop: 16 }}
										bordered
										locale={{ emptyText: 'Pas de donnée' }}
										rowKey="id"
										size="small"
										pagination={false}
										dataSource={spaces}
										columns={[
											{ title: 'Nom', dataIndex: 'name' },
											{ key: 'actions', width: 50, render: (_, record) => <Button onClick={handleDeleteSpace(record.id)} type="link" danger icon={<DeleteOutlined />}></Button> },
										]}
										onRow={record => {
											return {
												onClick: () => {
													handleSelectedSpace(record.id)
												},
											}
										}}
									/>
								</Section>
							</>
						)}

						{currentStep === 5 && (
							<>
								<Title level={4}>Pouvez-vous détailler ici les champs sur-mesure que vous souhaitez ?</Title>
								<div style={{ marginBottom: 32 }}>Ces champs vont structurer vos dossiers événements pour votre brief commercial et opérationnel.</div>
								<Section
									title={
										<Checkbox checked={isUnknownCustomFields} onChange={handleChangeIsUnknownCustomFields}>
											Je ne sais pas, je souhaite être conseillé(e)
										</Checkbox>
									}
									addon={
										<Button onClick={handleOpenCustomFieldModal} type="primary" icon={<PlusOutlined />}>
											Ajouter un champ sur mesure
										</Button>
									}
								>
									<Table
										style={{ marginTop: 16 }}
										bordered
										locale={{ emptyText: 'Pas de donnée' }}
										rowKey="id"
										size="small"
										pagination={false}
										dataSource={customFields}
										columns={[
											{ title: 'Nom', dataIndex: 'name' },
											{ title: 'Affectation', dataIndex: 'collection' },
											{
												title: 'Type',
												dataIndex: 'type',
												render: (val, record) => (
													<div>
														<div style={{ fontWeight: 'bold' }}>{mappingTypes().find(({ value }) => value === val)?.label}</div>
														<div>
															{record.data?.map(value => (
																<Tag key={value}>{value}</Tag>
															))}
														</div>
													</div>
												),
											},
											{ key: 'actions', width: 50, render: (_, record) => <Button onClick={handleDeleteCustomField(record.id)} type="link" danger icon={<DeleteOutlined />}></Button> },
										]}
										onRow={record => {
											return {
												onClick: () => {
													handleSelectedCustomField(record.id)
												},
											}
										}}
									/>
								</Section>
							</>
						)}

						{currentStep === 6 && (
							<>
								<Title level={4}>Quels sont les statuts dont vous avez besoin pour classer vos demandes d'événements ?</Title>
								<div style={{ marginBottom: 32 }}>Nouvelle demande, Demande en cours, Demande validés, ...</div>
								<Section
									title={
										<Checkbox checked={isUnknownStatus} onChange={handleChangeIsUnknownStatus}>
											Je ne sais pas, je souhaite être conseillé(e)
										</Checkbox>
									}
									addon={
										<Button onClick={handleOpenStatusModal} type="primary" icon={<PlusOutlined />}>
											Ajouter un statut
										</Button>
									}
								>
									<Table
										style={{ marginTop: 16 }}
										bordered
										locale={{ emptyText: 'Pas de donnée' }}
										rowKey="id"
										size="small"
										pagination={false}
										dataSource={status}
										columns={[
											{ title: 'Couleur', dataIndex: 'color', render: v => <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: v }} /> },
											{ title: 'Nom', dataIndex: 'name' },
											{ title: 'Ordre', dataIndex: 'order' },
											{ key: 'actions', width: 50, render: (_, record) => <Button onClick={handleDeleteStatus(record.id)} type="link" danger icon={<DeleteOutlined />}></Button> },
										]}
										onRow={record => {
											return {
												onClick: () => {
													handleSelectedStatus(record.id)
												},
											}
										}}
									/>
								</Section>
							</>
						)}

						{currentStep === 7 && (
							<>
								<Title level={4}>Quelles sont les sources de demande de votre établissement ?</Title>
								<div style={{ marginBottom: 32 }}>Cela vous permettra ensuite d'analyser aussi vos canaux de demande.</div>
								<Section
									title={
										<Checkbox checked={isUnknownSources} onChange={handleChangeIsUnknownSources}>
											Je ne sais pas, je souhaite être conseillé(e)
										</Checkbox>
									}
									addon={
										<Button onClick={handleOpenSourceModal} type="primary" icon={<PlusOutlined />}>
											Ajouter une source
										</Button>
									}
								>
									<Table
										style={{ marginTop: 16 }}
										bordered
										locale={{ emptyText: 'Pas de donnée' }}
										rowKey="id"
										size="small"
										pagination={false}
										dataSource={sources}
										columns={[
											{ title: 'Nom', dataIndex: 'name' },
											{ key: 'actions', width: 50, render: (_, record) => <Button onClick={handleDeleteSource(record.id)} type="link" danger icon={<DeleteOutlined />}></Button> },
										]}
										onRow={record => {
											return {
												onClick: () => {
													handleSelectedSource(record.id)
												},
											}
										}}
									/>
								</Section>
							</>
						)}
						{currentStep === 8 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Vous pouvez maintenant créer vos modèles d'email pour votre futur espace Booking Shake.
								</Title>
								<Section
									title={
										<Checkbox checked={isUnknownTemplates} onChange={handleChangeIsUnknownTemplates}>
											Je souhaite bénéficier des modèles email imaginé par Booking Shake.
										</Checkbox>
									}
									addon={
										<Button onClick={handleOpenTemplateModal} type="primary" icon={<PlusOutlined />}>
											Ajouter un modèle d'email
										</Button>
									}
								>
									<Table
										style={{ marginTop: 16 }}
										bordered
										locale={{ emptyText: 'Pas de donnée' }}
										rowKey="id"
										size="small"
										pagination={false}
										dataSource={templates}
										columns={[
											{ title: 'Nom', dataIndex: 'nom' },
											{ title: 'Objet', dataIndex: 'objet' },
											{ key: 'actions', width: 50, render: (_, record) => <Button onClick={handleDeleteTemplate(record.id)} type="link" danger icon={<DeleteOutlined />}></Button> },
										]}
										onRow={record => {
											return {
												onClick: () => {
													handleSelectedTemplate(record.id)
												},
											}
										}}
									/>
								</Section>
							</>
						)}
						{currentStep === 9 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Pour nous aider à configurer vos devis et factures, complétez les informations ci-dessous
								</Title>
								<div style={{ marginBottom: 32 }}>
									<Title level={5}>Allez-vous utiliser notre outil :</Title>
									<Checkbox checked={isGonnaUseQuote} onChange={handleChangeIsGonnaUseQuote}>
										de devis
									</Checkbox>
									<Checkbox checked={isGonnaUseInvoice} onChange={handleChangeIsGonnaUseInvoice}>
										de facture
									</Checkbox>
								</div>
								<div style={{ marginBottom: 32 }}>
									<Title level={5}>Allez-vous émettre des devis/factures en :</Title>
									<Checkbox checked={isGonnaUseFrenchQI} onChange={handleChangeIsGonnaUseFrenchQI}>
										français
									</Checkbox>
									<Checkbox checked={isGonnaUseEnglishQI} onChange={handleChangeIsGonnaUseEnglishQI}>
										anglais
									</Checkbox>
								</div>
								<Section title="Raison social">
									<Input size="small" placeholder="Raison social" style={{ width: '100%' }} value={venue?.raisonsocial} onChange={handleChangeVenue('raisonsocial')} />
								</Section>
								<Section title="Capital social">
									<Input size="small" placeholder="Capital social" style={{ width: '100%' }} value={venue?.capitalSocial} onChange={handleChangeVenue('capitalSocial')} />
								</Section>
								<Section title="SIREN">
									<Input size="small" placeholder="SIREN" style={{ width: '100%' }} value={venue?.siren} onChange={handleChangeVenue('siren')} />
								</Section>
								<Section title="Numéro TVA">
									<Input size="small" placeholder="Numéro TVA" style={{ width: '100%' }} value={venue?.numeroTVA} onChange={handleChangeVenue('numeroTVA')} />
								</Section>
								<Section title="IBAN">
									<Input size="small" placeholder="IBAN" style={{ width: '100%' }} value={venue?.rib} onChange={handleChangeVenue('rib')} />
								</Section>
								<Section title="Adresse">
									<Input size="small" placeholder="Adresse" style={{ width: '100%' }} value={venue?.adresse} onChange={handleChangeVenue('adresse')} />
								</Section>
								<Section title="Code postal">
									<Input size="small" placeholder="Code postal" style={{ width: '100%' }} value={venue?.codePostal} onChange={handleChangeVenue('codePostal')} />
								</Section>
								<Section title="Ville">
									<Input size="small" placeholder="Ville" style={{ width: '100%' }} value={venue?.ville} onChange={handleChangeVenue('ville')} />
								</Section>

								<Section title="Échéance pour vos devis">
									<InputNumber size="small" placeholder="Échéance pour vos devis" style={{ width: '100%' }} value={venue?.echeanceDevis} onChange={handleChangeVenue('echeanceDevis', 'value')} />
								</Section>
								<Section title="Paiement des factures (à réception ou nombre de jours)">
									<InputNumber size="small" placeholder="Paiement des factures (à réception ou nombre de jours)" style={{ width: '100%' }} value={venue?.echeanceFacture} onChange={handleChangeVenue('echeanceFacture', 'value')} />
								</Section>
								<Section title="% d'acompte par défaut">
									<InputNumber size="small" placeholder="% d'acompte par défaut" style={{ width: '100%' }} value={venue?.acompte} onChange={handleChangeVenue('acompte', 'value')} />
								</Section>

								<Section title="Choisissez les colonnes de vos devis/factures">
									<Select
										mode="multiple"
										options={[
											{ value: 'price_excluding_vat', label: 'Prix HT' },
											{ value: 'vat', label: 'TVA' },
											{ value: 'price_including_vat', label: 'Prix TTC' },
											{ value: 'quantity', label: 'Quantité' },
											{ value: 'discount', label: 'Remise' },
											{ value: 'total_excluding_vat', label: 'Total HT' },
											{ value: 'total_including_vat', label: 'Total TTC' },
										]}
										size="small"
										placeholder="Choisissez les colonnes de vos devis/factures"
										style={{ width: '100%' }}
										value={venue?.productsColumns}
										onChange={handleChangeVenue('productsColumns', 'value')}
									/>
								</Section>
								<Section title="Vos CGV">
									<Upload onChange={handleChangeFile('cgv')} listType="picture-card" fileList={files.cgv} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
								<Section title="Faut-il implémenter les CGV dans le devis ?">
									<Checkbox checked={showCGVInQuote === 'yes'} onChange={handleShowCGVInQuote('yes')}>
										Oui
									</Checkbox>
									<Checkbox checked={showCGVInQuote === 'no'} onChange={handleShowCGVInQuote('no')}>
										Non
									</Checkbox>
									<Checkbox checked={showCGVInQuote === 'no_cgv'} onChange={handleShowCGVInQuote('no_cgv')}>
										Je n'ai pas de CGV
									</Checkbox>
								</Section>
								<Section title="Mention sur vos devis">
									<Input.TextArea autoSize size="small" placeholder="Mention sur vos devis" style={{ width: '100%' }} value={venue?.defaultBaseComments} onChange={handleChangeVenue('defaultBaseComments')} />
								</Section>
								<Section title="Souhaitez-vous activer l'option de signature en ligne des devis">
									<Switch size="small" checked={venue?.isElectronicSignatureActive} onChange={handleChangeVenue('isElectronicSignatureActive', 'value')} />
								</Section>
								<Section title="Mention sur vos factures">
									<Input.TextArea autoSize size="small" placeholder="Mention sur vos factures" style={{ width: '100%' }} value={venue?.conditionsFacture} onChange={handleChangeVenue('conditionsFacture')} />
								</Section>
								<Section title="Vos exemple(s) devis">
									<Text type="secondary" style={{ fontSize: 12 }}>
										Avez des exemples de vos devis actuel à nous communiquer pour nous inspirer ?
									</Text>
									<Upload onChange={handleChangeFile('quoteExample')} listType="picture-card" fileList={files.quoteExample} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
								<Section title="Vos exemple(s) factures">
									<Text type="secondary" style={{ fontSize: 12 }}>
										Avez des exemples de vos factures actuel à nous communiquer pour nous inspirer ?
									</Text>
									<Upload onChange={handleChangeFile('invoiceExample')} listType="picture-card" fileList={files.invoiceExample} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
							</>
						)}
						{currentStep === 10 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Merci de préciser les connexions que vous souhaitez avoir ?
								</Title>
								<Section title="Intégrations">
									<div>
										<Checkbox checked={integrations.googleGmail} onChange={handleIntegration('googleGmail')}>
											Google Gmail
										</Checkbox>
									</div>
									<div>
										<Checkbox checked={integrations.googleCalendar} onChange={handleIntegration('googleCalendar')}>
											Google Calendar
										</Checkbox>
									</div>
									<div>
										<Checkbox checked={integrations.microsoftOutlook} onChange={handleIntegration('microsoftOutlook')}>
											Microsoft Outlook
										</Checkbox>
									</div>
									<div>
										<Checkbox checked={integrations.stripe} onChange={handleIntegration('stripe')}>
											Stripe
										</Checkbox>
									</div>
									<div>
										<Checkbox checked={integrations.stripe} onChange={handleIntegration('zenchef')}>
											Zenchef
										</Checkbox>
									</div>
									<div>
										<Checkbox checked={integrations.stripe} onChange={handleIntegration('guestonline')}>
											Guestonline
										</Checkbox>
									</div>
									<div>
										<Checkbox checked={integrations.stripe} onChange={handleIntegration('thais')}>
											Thais-PMS
										</Checkbox>
									</div>
									<div>
										<Checkbox checked={integrations.stripe} onChange={handleIntegration('mews')}>
											Mews PMS
										</Checkbox>
									</div>
								</Section>
							</>
						)}
						{currentStep === 11 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Dans cet étape, vous pouvez nous partager vos documents pour nous aider à mieux vous connaître.
								</Title>
								<Section title="Avez-vous des plaquettes commerciales ? Pouvez-vous nous les partager ?">
									<Upload onChange={handleChangeFile('plaquette')} listType="picture-card" fileList={files.plaquette} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
								<Section title="Avez-vous un catalogue de produits ou services à implémenter dans Booking Shake ? (Carte, Menu, Grille tarifaire, tableau excel...)">
									<Upload onChange={handleChangeFile('catalog')} listType="picture-card" fileList={files.catalog} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
								<Section title="Avez-vous un/des exemples de fiche de fonction, feuille de route ou brief opérationnel à nous partager ?">
									<Upload onChange={handleChangeFile('function')} listType="picture-card" fileList={files.function} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
								<Section title="Avez-vous une base de données clients à importer ?">
									<Text type="secondary" style={{ fontSize: 12 }}>
										Merci de respecter le format de tableur qui vous a été transmis au préalable par email.
									</Text>
									<Upload onChange={handleChangeFile('db')} listType="picture-card" fileList={files.db} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
							</>
						)}
						{currentStep === 12 && (
							<>
								<Title style={{ marginBottom: 32 }} level={4}>
									Dernière étape !
								</Title>
								<Section title="Insérez votre devis Booking Shake signé">
									<Upload onChange={handleChangeFile('signedQuote')} listType="picture-card" fileList={files.signedQuote} beforeUpload={() => false}>
										<div>
											<PlusOutlined />
										</div>
									</Upload>
								</Section>
								<Section title="Si ce n'est pas vous, à qui devons nous adresser les factures ?">
									<Input size="small" placeholder="Prénom" style={{ width: '100%', marginBottom: 8 }} value={invoiceTarget?.firstname} onChange={handleChangeInvoiceTarget('firstname')} />
									<Input size="small" placeholder="Nom" style={{ width: '100%', marginBottom: 8 }} value={invoiceTarget?.lastname} onChange={handleChangeInvoiceTarget('lastname')} />
									<Input size="small" placeholder="Email" style={{ width: '100%', marginBottom: 8 }} value={invoiceTarget?.email} onChange={handleChangeInvoiceTarget('email')} />
								</Section>
							</>
						)}
						{currentStep === 13 && (
							<div style={{ marginTop: 32, fontSize: 16 }}>
								<div style={{ textAlign: 'center', fontWeight: 'bold' }}>Félicitations !</div>
								<div style={{ textAlign: 'center' }}>Nous avons bien reçu tous les éléments de création de compte.</div>
								<div style={{ textAlign: 'center' }}>Nous reviendrons vers vous dans les plus brefs délais.</div>
							</div>
						)}
					</>
				)}
			</Layout>
		</>
	)
}
export default App
